<!--
File: TrafficEditFrom.vue
Description: form for adding/editing a single traffic data.
-->
<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon">
        <div class="card-icon"> <md-icon>perm_identity</md-icon> </div>
        <h4 class="title"> {{ $t(`route.traffic_${oper}`) }} </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field :class="[
                { 'md-valid': !errors.has('description') && touched.description },
                { 'md-error': errors.has('description') }
              ]">
                <label>{{ $t('stdCols.description') }}</label>
                <md-input v-model="description" type="text" data-vv-name="description" required
                  v-validate="modelValidations.description"></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('description')">
                    close
                  </md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('description') && touched.description">
                    done
                  </md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field class="md-field">
                <label>{{ $t('traffic.site_id') }}</label>
                <md-input v-model="traffic_site_id" type="number" data-vv-name="traffic_site_id" required></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field class="md-field">
                <label>{{ $t('traffic.photo_filename') }}</label>
                <md-input v-model="photo_filename" type="text" data-vv-name="photo_filename"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onCancel">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { SlideYDownTransition } from 'vue2-transitions'
  import Swal from 'sweetalert2'

  export default {
    name: 'traffic-edit-form',
    props: {
      oper: String
    },
    components: {
      SlideYDownTransition
    },
    created() {
      const { tid = null } = this.$route.params
      this.tid = tid
      if (this.oper === 'upd' && this.tid) {
        this.$store.dispatch('LOAD_TRAFFIC_BY_ID', this.tid).then((res) => {
          const traffic = Array.isArray(res) ? res[0] : res
          this.description = traffic.description
          this.traffic_site_id = traffic.traffic_site_id
          this.photo_filename = traffic.photo_filename
        })
      }
      this.$store.dispatch('LOAD_TRAFFIC_INSTALLATION').then(() => {
        //      const defUnit = this.unitsList[0].id
        //      console.log(this.units)
      })

    },
    data() {
      return {
        tid: null,
        description: null,
        traffic_site_id: null,
        photo_filename: null,
        touched: {
          description: false,
          traffic_site_id: false,
          photo_filename: false,
        },
        modelValidations: {
          description: {
            required: true,
            min: 3
          },
          traffic_site_id: {
            required: true,
            //numeric: true
          },
          photo_filename: {
            min: 3
          }
        }
      }
    },
    methods: {
      onCancel() {
        this.$router.push('/condition_data/traffic')
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          if (isValid) {
            const theTraffic = {
              description: this.description,
              traffic_site_id: this.traffic_site_id,
              photo_filename: this.photo_filename
            }

            const alert = {
              type: 'success',
              text: this.$t('route.traffic_data') + this.$t(`label.was_saved`, {
                name_ru: theTraffic.description,
                name_en: theTraffic.description
              }),
              footer: ''
            }
            const reqData = this.oper === 'add' ? theTraffic : { id: this.tid, theTraffic }
            this.$store.dispatch(`${this.oper.toUpperCase()}_TRAFFIC_INSTALLATION`, reqData).then(
                () => {
                  Swal.fire(alert).then(() => {
                    this.touched.description = false
                    this.touched.traffic_site_id = false
                    this.touched.photo_filename = false
                    this.$nextTick(() => this.$validator.reset())
                  })
                },
                (err) => {
                  alert.type = 'error'
                  alert.text = this.$t('route.traffic_data') + this.$t(`label.was_not_saved`, {
                    name_ru: theTraffic.description,
                    name_en: theTraffic.description
                  })
                  alert.footer = err
                  Swal.fire(alert)
                }
              )
          }
        })
        this.$router.push('/condition_data/traffic')
      },
      onUnitChange() {
        //      this.$el.validity = true
      },
    },
    watch: {
      description() {
        this.touched.description = true
      },
      traffic_site_id() {
        this.touched.traffic_site_id = true
      },
      photo_filename() {
        this.touched.photo_filename = true
      }
    },
    computed: {
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
